import * as React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

import { Layout, Title, ContactForm } from "../components";
import { useRef, useState } from "react";
import { useEffect } from "react";

const Map = ({
  center,
  zoom,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
}) => {
  const ref = useRef();

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });

    const marker = new google.maps.Marker({
      position: center,
      map: map,
      title: "Cémpes Vastgoed",
    });

    const infowindow = new google.maps.InfoWindow({
      content: `<p><strong>Cémpes Vastgoed</strong><br />Buizerd 24<br />1261 SR Blaricum</p>`,
    });

    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
    });
  });

  return <div ref={ref} id="map" className="w-full h-[300px]" />;
};

const IndexPage = () => {
  return (
    <>
      <title>
        Contact | Cémpes Vastgoed - Dé alleskunner voor uw vastgoedontwikkeling
      </title>

      <Layout>
        <Title text="Het leveren van een toegevoegde waarde" />

        <p className="mt-5">
          Bent u benieuwd wat Cémpes Vastgoed voor u kan betekenen? <br />
          Neem dan vrijblijvend contact op voor meer informatie.
        </p>

        <div className="grid sm:grid-cols-12 gap-6 mt-5">
          <div className="col-span-6">
            <ContactForm />
          </div>
          <div className="col-span-6">
            <div>
              <Wrapper
                region="nl"
                apiKey={"AIzaSyAqLEKJPxCKRza6QTAM_AdBRjBduzu7EJE"}
              >
                <Map center={{ lat: 52.2861151, lng: 5.2734431 }} zoom={17} />
              </Wrapper>
            </div>
            <div className="bg-bluegray text-white p-3">
              Cémpes Vastgoed <br />
              Buizerd 24 - 1261 SR Blaricum <br />
              +31 (0)35 888 5 666 <br />
              <a
                href="mailto:info@cempes.nl"
                className="text-white hover:underline"
              >
                info@cempes.nl
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
